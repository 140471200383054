import { ErrorInfo } from "react";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "../components/ErrorBoundary";
import ErrorPage from "../pages/ErrorPage";
import { addAppError } from "../state/appErrors";
import Root from "./Root";

const handleAppError = (error: Error, errorInfo: ErrorInfo) => {
  console.log(error, errorInfo);
  addAppError(error);
};

const Entrypoint: React.FC = () => (
  <BrowserRouter>
    <ErrorBoundary renderError={ErrorPage} onError={handleAppError}>
      <Root />
    </ErrorBoundary>
  </BrowserRouter>
);

export default Entrypoint;
