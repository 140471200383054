import { useCallback, useState } from "react";

// Provides a callback for proper error reporting, by moving the error into the
// render context.
// This enables the error to be caught by the React's error boundary.
const useThrow = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_error, setError] = useState(undefined);
  const thrower = useCallback((error: unknown) => {
    setError(() => {
      throw error;
    });
  }, []);
  return thrower;
};

export default useThrow;
