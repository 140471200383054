import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { GAP, GenericErrorShape } from "../GenericErrorPage";
import { validatorKeyNotAvailable } from "./common";

const params = {
  lookupMap: {
    personAlreadyEnrolled: {
      title: "Person Already Enrolled",
      hint: [
        "You are already enrolled with this biometrics server. There is no need to enroll with a biometrics server more than once.",
        GAP,
        "If you have lost access to the key you have enrolled with you will have to wait for the next biometrics server generation. Biometrics servers are rotated roughly every 6 months.",
      ],
      customActions: [
        <Button
          key="custom-action-to-authenticate"
          fullWidth
          size="large"
          variant="contained"
          color="success"
          component={Link}
          to="./../../authenticate"
        >
          Authenticate
        </Button>,
      ],
    } as GenericErrorShape,
    publicKeyAlreadyUsed: {
      title: "Public Key Already Used",
      hint: [
        "They public key you are trying to enroll with is already used.",
        GAP,
        "This is highly unlikely you have generated the same random key, so it is most likely an operational error on your side. Please double-check your setup, maybe you are using someone else’s key or this is your key but you are already enrolled. There is no need to enroll with a particular biometrics server more than once.",
        "It is also a possibility that your private key has leaked, and someone else already used it to enroll with this biometric server.",
      ],
    } as GenericErrorShape,
    validatorKeyNotAvailable,
  },
  defaultLookup: {
    title: "Enrollment Failed",
    hint: ["Something went wrong."],
  } as GenericErrorShape,
} as const;

export default params;
