import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import React from "react";
import AppErrorsSnackbar from "./components/AppErrorsSnackbar";
import AppUpdateSnackbar from "./components/AppUpdateSnackbar";
import Entrypoint from "./routes/Entrypoint";
import theme from "./theme";

const App: React.FC = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <Entrypoint />
        <AppUpdateSnackbar />
        <AppErrorsSnackbar />
      </CssBaseline>
    </ThemeProvider>
  </StyledEngineProvider>
);

export default App;
