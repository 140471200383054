import React, { useEffect } from "react";
import { Subject } from "rxjs";

const subject = new Subject<ServiceWorkerRegistration>();

type Props = {
  onUpdate: (registration: ServiceWorkerRegistration) => void;
};

const AppUpdateContext: React.FC<Props> = ({ onUpdate }) => {
  useEffect(() => {
    subject.subscribe(onUpdate);
  }, [onUpdate]);
  return null;
};

export default AppUpdateContext;

export const notifyUpdate = (registration: ServiceWorkerRegistration) =>
  subject.next(registration);
