import React, { ErrorInfo } from "react";

export type ErrorProps = {
  error: Error;
};

type Props = {
  children: React.ReactNode;
  onError: (error: Error, errorInfo: ErrorInfo) => void;
  renderError: React.ComponentType<ErrorProps>;
};

type State = {
  error: Error | null;
};

class ErrorBoundary extends React.Component<Props> {
  state: State = { error: null };

  static getDerivedStateFromError = (error: Error) => ({ error });

  componentDidCatch = (error: Error, errorInfo: ErrorInfo) => {
    this.props.onError(error, errorInfo);
  };

  render() {
    const { error } = this.state;
    if (error) {
      const Component = this.props.renderError;
      return <Component error={error} />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
