import React from "react";
import { styled } from "@mui/system";
import logo from "../assets/logo.png";

type Props = {
  children: React.ReactNode;
  logo?: true;
  className?: string;
};

const LayoutContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  minWidth: "100vw",
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(2, 2),
  boxSizing: "border-box",
  gap: theme.spacing(2),
}));

const Logo: React.FC<
  React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >
> = (props) => <img src={logo} alt="logo" {...props} />;

const LayoutLogo = styled(Logo)({
  display: "block",
  height: "50vh",
  objectFit: "contain",
});

const Layout: React.FC<Props> = (props) => {
  const { children, logo: showLogo, className } = props;
  return (
    <LayoutContainer className={className}>
      {showLogo && <LayoutLogo />}
      {children}
    </LayoutContainer>
  );
};

export default Layout;
