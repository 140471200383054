import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import Layout from "./Layout";

const LoadingPage: React.FC = () => {
  return (
    <Layout logo>
      <CircularProgress />
    </Layout>
  );
};

export default LoadingPage;
