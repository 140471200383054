import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Skeleton,
  Stack,
  SvgIconProps,
  Typography,
} from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import type { ExtrinsicStatus } from "@polkadot/types/interfaces/author";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import useTheme from "@mui/system/useTheme";
import { ApiRx } from "@polkadot/api";
import { formatDecimal } from "@polkadot/util";
import { Route, Routes } from "react-router";
import { Link } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/system";
import { StateProps, StateVariant } from "@tagged-state/core";
import { skip } from "rxjs";
import RpcError from "@polkadot/rpc-provider/coder/error";
import { Temporal } from "@js-temporal/polyfill";
import { ApiGuard, useApi } from "../subsystem/api/state";
import ApiInit from "../subsystem/api/ApiInit";
import { FaceTecSDKGuard } from "../subsystem/facetec/sdk/state";
import FaceTecSDKInit from "../subsystem/facetec/sdk/FaceTecSDKInit";
import { NodeConnectionParamsContext } from "../structure/NodeConnectionParams";
import ServiceWorkerGuard from "../components/ServiceWorkerGuard";
import { addAppError } from "../state/appErrors";
import faceTecInit from "../subsystem/facetec/logic/faceTecInit";
import { createApi, createProvider } from "../subsystem/humanodePeerApi/api";
import {
  getFacetecDeviceSdkParams,
  getFacetecSessionToken,
  enroll,
  authenticate,
  setKeys,
  rotateKeys,
  getSystemChain,
} from "../subsystem/humanodePeerApi/wrappers";
import useAsyncCallback from "../hooks/useAsyncCallback";
import faceTecUnload from "../subsystem/facetec/logic/faceTecUnload";
import FaceTecSessionInit from "../subsystem/facetec/sessionToken/FaceTecSessionTokenInit";
import {
  FaceTecSessionTokenGuard,
  useFaceTecSessionToken,
} from "../subsystem/facetec/sessionToken/state";
import useStartCapture from "../subsystem/facetec/useStartCapture";
import makeLegacyProcessor, {
  Effect as LegacyEffect,
  LivenessData,
} from "../subsystem/facetec/makeLegacyProcessor";
import {
  isRpcErrorInterface,
  isShouldRetry,
} from "../subsystem/humanodePeerApi/typeGuards";
import { ChildrenProps } from "../reactExt";
import { SyncState } from "../lib/dashboard/syncState";
import { BalanceState } from "../lib/dashboard/balance";
import { EpochProgressState } from "../lib/dashboard/epochProgress";
import { BioauthStatusState } from "../lib/dashboard/bioauthStatus";
import { SyncStateGuard } from "../subsystem/dashboard/SyncStateInit";
import { EpochProgressGuard } from "../subsystem/dashboard/EpochProgressInit";
import { BalanceGuard } from "../subsystem/dashboard/BalanceInit";
import DashboardInit from "../subsystem/dashboard/DashboardInit";
import { useBlockTime } from "../util/blockTime";
import { BNtoUnit } from "../util/convert";
import { BioauthStatusGuard } from "../subsystem/dashboard/BioauthStatusInit";
import { useCountdown } from "../hooks/useCountdown";
import makeProcessor, {
  LivenessDataHandler,
} from "../subsystem/facetec/makeProcessor";
import enrollV2, {
  EnrollV2Response,
} from "../subsystem/humanodePeerApi/rpcCalls/enrollV2";
import authenticateV2, {
  AuthenticateV2Response,
} from "../subsystem/humanodePeerApi/rpcCalls/authenticateV2";
import sendAuthenticateTx, {
  SendAuthenticateTxState,
} from "../subsystem/humanodePeerApi/rpcCalls/sendAuthenticateTx";
import { useConfig, useConfigLoader } from "../config";
import {
  AuthenticateV2ErrorPage,
  EnrollV2ErrorPage,
  AuthTxErrorPage,
} from "./SpecificErrorPages";
import { useBoundErrorPage } from "./ErrorPage";
import Layout from "./Layout";

const CompleteIcon = styled(CheckCircleIcon)<SvgIconProps>({
  fill: "#00B894",
  height: 40,
  width: 40,
});

const PanelBG = styled("div")({
  backgroundColor: "black",
  padding: "5px",
  height: "30px",
  width: "100%",
  textAlign: "center",
  position: "relative",
  borderRadius: "15px",
});

const ProgressBarLinar = styled("div")({
  color: "#000",
  position: "absolute",
  left: "5px",
  top: "5px",
  height: "20px",
  overflow: "hidden",
  borderRadius: "15px",
});

const useDots = () => {
  const [counter, setCounter] = React.useState(60);

  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter + 1), 1000);
    return () => clearInterval(Number(timer));
  }, [counter]);

  const str = useMemo(() => ".".repeat(counter % 4), [counter]);

  return str;
};

const ServiceWorkerLoadingPage: React.FC = () => (
  <Layout logo>
    <CircularProgress />
    <Typography>Waiting for the service worker...</Typography>
    <Typography variant="body2" textAlign="center">
      If it takes too long, you might be using an incompatible web browser.
      <br />
      Try using Google Chrome, it is known to work on all platforms.
      <br />
      Try disabling unnecessary browser extensions, this may interfere with the
      operation of the web application.
    </Typography>
  </Layout>
);

const ConfigLoaderPending: React.FC = () => (
  <Layout logo>
    <CircularProgress />
    <Typography>Loading conficuration...</Typography>
  </Layout>
);

const ApiLoadingPage: React.FC = () => (
  <Layout logo>
    <CircularProgress />
    <Typography>Connecting to the peer...</Typography>
  </Layout>
);

const FaceTecSDKLoadingPage: React.FC = () => (
  <Layout logo>
    <CircularProgress />
    <Typography>Waiting for the biometric engine...</Typography>
  </Layout>
);

const FaceTecSessionLoadingPage: React.FC = () => (
  <Layout logo>
    <CircularProgress />
    <Typography>Waiting for the biometric server session...</Typography>
  </Layout>
);

type ProgressBarProps = {
  title: string;
  percent: number;
};
const ProgressBar: React.FC<ProgressBarProps> = (props) => {
  const { title, percent } = props;
  const ref = useRef<HTMLDivElement>(null!);
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
  }, []);

  return (
    <PanelBG ref={ref}>
      <ProgressBarLinar
        style={{
          width: `calc(${percent}% - 10px)`,
          background:
            percent === 100
              ? "#00B894"
              : "linear-gradient(to right, #55EFC4, #EBEBAA, #FFEAA7)",
        }}
      >
        <Typography fontSize={14} sx={{ width, lineHeight: 1.25 }}>
          {title}
        </Typography>
      </ProgressBarLinar>
      <Typography fontSize={14} sx={{ width, lineHeight: 1.25 }}>
        {title}
      </Typography>
    </PanelBG>
  );
};

const BioauthStatusInactive: React.FC = () => (
  <Stack>
    <Box display="flex" alignItems="center" gap={1}>
      <WarningRoundedIcon style={{ fill: "#FFEAA7", height: 20 }} />
      <Typography
        variant="subtitle2"
        color="#FFEAA7"
        fontWeight={600}
        align="left"
      >
        Inactive
      </Typography>
    </Box>
    <Typography
      variant="subtitle2"
      color="#FFEAA7"
      fontSize={12}
      fontWeight={600}
      align="left"
    >
      A valid bioauthentication is required to be a validator
    </Typography>
  </Stack>
);

const BioauthStatusUnknown: React.FC = () => (
  <Typography variant="subtitle2" color="gray" fontWeight={600} align="left">
    Unknown
  </Typography>
);

const durationToTimeString = (duration: Temporal.Duration) => {
  const hours = Math.floor(duration.total({ unit: "hours" }));
  const minutes = Math.floor(duration.total({ unit: "minutes" }) % 60)
    .toString()
    .padStart(2, "0");
  const seconds = Math.floor(duration.total({ unit: "seconds" }) % 60)
    .toString()
    .padStart(2, "0");

  return `${hours}:${minutes}:${seconds}`;
};

const BioauthStatusActive: React.FC<
  StateProps<BioauthStatusState>["active"]
> = (props) => {
  const { expiresAt } = props;
  const init = useMemo(() => Math.max(expiresAt - Date.now(), 0), [expiresAt]);
  const millis = useCountdown(init);

  const timer = useMemo(
    () =>
      durationToTimeString(Temporal.Duration.from({ milliseconds: millis })),
    [millis],
  );

  return (
    <Typography
      variant="subtitle2"
      color="#00B894"
      fontWeight={600}
      align="left"
    >
      {timer}
    </Typography>
  );
};

const SyncingState: React.FC<StateProps<SyncState>["syncing"]> = (props) => {
  const { currentBlock, highestBlock } = props;
  const dots = useDots();

  const percent = useMemo(
    () => (highestBlock ? currentBlock / (highestBlock / 100) : 0),
    [currentBlock, highestBlock],
  );

  const syncStateLabel = useMemo(
    () =>
      `${formatDecimal(String(currentBlock))} / ${formatDecimal(
        String(highestBlock),
      )} blocks synced`,
    [currentBlock, highestBlock],
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        minHeight: 50,
      }}
    >
      <Typography minWidth={70} color="#aaaaaa">
        Status
      </Typography>
      <Stack
        sx={{ width: "294px", alignItems: "flex-start", color: "#747474" }}
      >
        <ProgressBar title={"Syncing" + dots} percent={percent} />
        <Typography paddingLeft="8px" fontSize={12}>
          {syncStateLabel}
        </Typography>
      </Stack>
    </Box>
  );
};

const SyncedState: React.FC = () => (
  <>
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        minHeight: 30,
      }}
    >
      <Typography minWidth={70} color="#aaaaaa">
        Status
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <CheckCircleIcon style={{ fill: "#00B894", height: 20 }} />
        <Typography
          variant="subtitle2"
          color="#00B894"
          fontWeight={600}
          align="left"
        >
          Synced
        </Typography>
      </Box>
    </Box>
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "baseline",
      }}
    >
      <Typography pt={"3px"} minWidth={70} color="#aaaaaa">
        Bioauth
      </Typography>
      <BioauthStatusGuard
        uninit={UninitGenericState}
        active={BioauthStatusActive}
        inactive={BioauthStatusInactive}
        unknown={BioauthStatusUnknown}
        error={ErrorGenericState}
      />
    </Box>
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        minHeight: 50,
      }}
    >
      <Typography pt={"3px"} minWidth={70} color="#aaaaaa">
        Epoch
      </Typography>
      <Box width="294px">
        <EpochProgressGuard
          uninit={UninitEpochProgressState}
          progress={ProgressEpochProgressState}
          error={ErrorGenericState}
        />
      </Box>
    </Box>
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        minHeight: 50,
      }}
    >
      <Typography minWidth={70} color="#aaaaaa">
        Balance
      </Typography>
      <Box width="294px">
        <BalanceGuard
          uninit={UninitGenericState}
          ready={SyncedBalanceState}
          error={ErrorGenericState}
        />
      </Box>
    </Box>
  </>
);

const SyncedBalanceState: React.FC<StateProps<BalanceState>["ready"]> = (
  props,
) => {
  const { api } = useApi();
  const {
    accountInfo: {
      data: { free },
    },
  } = props;

  const tokens = useMemo(() => {
    const tokenParts = BNtoUnit(
      free,
      api.registry.chainDecimals[0],
      api.registry.chainTokens[0],
    ).split(".");

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
          marginLeft: "15px",
        }}
      >
        <Typography fontSize={14}>{tokenParts[0]}</Typography>
        <Typography fontSize={12} color="#505050">
          .{tokenParts[1]?.substring(0, 4) || "0000"}
        </Typography>
      </Box>
    );
  }, [api.registry.chainDecimals, api.registry.chainTokens, free]);

  return (
    <PanelBG>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {tokens}
        <Typography fontSize={14} marginRight="9px" color="#747474">
          {api.runtimeChain.registry.chainTokens[0]}
        </Typography>
      </Box>
    </PanelBG>
  );
};

const UninitEpochProgressState: React.FC = () => (
  <Stack gap="5px">
    <Skeleton
      sx={{ transform: "inherit", borderRadius: "15px", minHeight: "30px" }}
      width="100%"
    />
    <Skeleton
      sx={{
        transform: "inherit",
        borderRadius: "5px",
        height: "14px",
        marginX: "8px",
      }}
      width={"calc(100% - 16px)"}
    />
  </Stack>
);

const ProgressEpochProgressState: React.FC<
  StateProps<EpochProgressState>["progress"]
> = (props) => {
  const { total, current } = props;
  const { api } = useApi();
  const totalTime = useBlockTime(api, total);
  const currentTime = useBlockTime(api, current);
  const dots = useDots();

  const percent = useMemo(
    () => (total ? current / (total / 100) : 0),
    [current, total],
  );

  const syncStateLabel = useMemo(
    () => (
      <Stack flexDirection="row" gap={1}>
        <Typography component="span" fontSize={12}>
          Total:{" "}
          <Typography fontSize={12} component="span" fontWeight={600}>
            {totalTime?.[1]}.
          </Typography>
        </Typography>
        <Typography component="span" fontSize={12}>
          Progress:
          <Typography fontSize={12} component="span" fontWeight={600}>
            {currentTime?.[1]}.
          </Typography>
        </Typography>
      </Stack>
    ),
    [currentTime, totalTime],
  );

  return (
    <Stack
      sx={{
        width: "100%",
        alignItems: "flex-start",
        color: "#747474",
        gap: "2px",
      }}
    >
      <ProgressBar title={"Progress" + dots} percent={percent} />
      <Box paddingLeft="8px" fontSize={12}>
        {syncStateLabel}
      </Box>
    </Stack>
  );
};

const ErrorGenericState: React.FC<StateProps<SyncState>["error"]> = ({
  error,
}) => (
  <Stack>
    <Skeleton
      sx={{ transform: "inherit", borderRadius: "15px", minHeight: "30px" }}
      width="100%"
    />
    <Typography paddingLeft="8px" color="error" fontSize={12}>
      Error: {error.message}
    </Typography>
  </Stack>
);

const UninitGenericState: React.FC = () => (
  <Skeleton
    sx={{ transform: "inherit", borderRadius: "15px", minHeight: "30px" }}
    width="100%"
  />
);

const UninitSyncState: React.FC = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      minHeight: 50,
    }}
  >
    <Typography minWidth={70} color="#aaaaaa">
      Status
    </Typography>
    <Skeleton
      sx={{ transform: "inherit", borderRadius: "15px", minHeight: "30px" }}
      width="100%"
    />
  </Box>
);

const DashboardView: React.FC = () => {
  const { api } = useApi();
  const [chainName, setChainName] = useState<string>();

  useEffect(() => {
    getSystemChain(api).then(setChainName);
  }, [api]);

  return (
    <Stack
      maxWidth="xs"
      sx={{
        width: "100%",
        backgroundColor: "#121212",
        borderRadius: "25px",
        display: "flex",
        flexDirection: "column",
        padding: "0px",
        gap: "15px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          minHeight: "30px",
          gap: "3px",
        }}
      >
        <Typography
          variant="body1"
          color="#aaaaaa"
          fontWeight={300}
          minWidth={70}
          align="left"
        >
          Chain
        </Typography>
        <Typography variant="subtitle2" fontWeight={600} align="left">
          {chainName ? chainName : <Skeleton width="100%" />}
        </Typography>
      </Box>
      <SyncStateGuard
        uninit={UninitSyncState}
        syncing={SyncingState}
        ready={SyncedState}
        error={ErrorGenericState}
      />
    </Stack>
  );
};

const RetryButton: React.FC = () => {
  const handleRetry = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <Button
      fullWidth
      size="large"
      variant="contained"
      color="primary"
      onClick={handleRetry}
    >
      Retry
    </Button>
  );
};

type CaptureAgainButtonProps = {
  onClick: () => void;
};

const CaptureAgainButton: React.FC<CaptureAgainButtonProps> = (props) => (
  <Button variant="outlined" onClick={props.onClick}>
    Capture again
  </Button>
);

const GoBackButton: React.FC = () => {
  return (
    <Button fullWidth variant="text" component={Link} to="./..">
      Go back
    </Button>
  );
};

const mapSubstrateErrors = (error: Error) => {
  if (
    isRpcErrorInterface(error) &&
    error.message ===
      "400: Unexpected transaction pool error: Invalid transaction validity: InvalidTransaction::Payment"
  ) {
    return new Error(
      "Inability to pay some fees (e.g. account balance too low)",
    );
  }

  return error;
};

const useExtendedError = (
  explainer: React.ReactNode,
  details?: React.ReactNode,
  goBackButton?: boolean,
): React.FC<{ error: Error }> => {
  const extension = (
    <>
      <Typography variant="h5" align="center" component="span">
        {explainer}
      </Typography>
      {details && (
        <Typography variant="body2" component="span">
          {details}
        </Typography>
      )}
      <RetryButton />
      {goBackButton && <GoBackButton />}
    </>
  );
  return useBoundErrorPage(extension);
};

const ApiConnector: React.FC<ChildrenProps> = (props) => {
  const nodeConnectionParams = useContext(NodeConnectionParamsContext);

  if (nodeConnectionParams === null) {
    throw new Error("nodeConnectionParams is null");
  }

  const { children } = props;

  const builder = useAsyncCallback(
    async () => {
      const provider = await createProvider(nodeConnectionParams.url);
      const api = await createApi({ provider, throwOnConnect: true });
      return { api };
    },
    addAppError,
    [nodeConnectionParams],
  );

  const ready = useCallback(() => <>{children}</>, [children]);

  const error = useExtendedError(
    <>No Connection to Peer</>,
    <>
      This might be caused by:
      <ul>
        <li>a misbehaving ngrok at the peer end,</li>
        <li>or no internet connectivity at the peer system or this device,</li>
        <li>
          or maybe the peer is not currently running to accept a connection,
        </li>
        <li>
          or peer was unable to bind to the expected port (can happen if peer is
          launched on a system that is shared together with other peers instead
          of a dedicated system).
        </li>
      </ul>
    </>,
  );

  return (
    <ApiInit builder={builder}>
      <ApiGuard
        uninit={ApiLoadingPage}
        pending={ApiLoadingPage}
        ready={ready}
        error={error}
      />
    </ApiInit>
  );
};

const FaceTecSDKInitializer: React.FC<ChildrenProps> = (props) => {
  const { children } = props;

  const { api } = useApi();
  const theme = useTheme();

  const facetecInitRoutine = useAsyncCallback(
    async () => {
      const params = await getFacetecDeviceSdkParams(api);
      return await faceTecInit({ ...params }, theme);
    },
    addAppError,
    [api, theme],
  );

  const facetecUnloadRoutine = useAsyncCallback(
    async () => {
      return await faceTecUnload();
    },
    addAppError,
    [api],
  );

  return (
    <FaceTecSDKInit
      faceTecInit={facetecInitRoutine}
      faceTecUnload={facetecUnloadRoutine}
    >
      {children}
    </FaceTecSDKInit>
  );
};

const FaceTecSDKFailedPage: React.FC = () => {
  const ErrorComponent = useExtendedError(
    <>Biometric Engine Failed to Initialize</>,
    <>
      We are unable to proceed with the bioauth flow because the biometric
      engine refused to operate.
    </>,
    true,
  );
  const error = useMemo(() => new Error("Biometric engine failed to init"), []);
  return <ErrorComponent error={error} />;
};

const FaceTecSessionInitializer: React.FC<ChildrenProps> = (props) => {
  const { children } = props;

  const { api } = useApi();

  const boundGetSessionToken = useAsyncCallback(
    () => {
      return getFacetecSessionToken(api).then((sessionToken) => ({
        sessionToken,
      }));
    },
    addAppError,
    [api],
  );

  const ready = useCallback(() => <>{children}</>, [children]);

  const error = useExtendedError(
    <>Error While Establishing Biometric Server Session</>,
  );

  return (
    <FaceTecSessionInit getSessionToken={boundGetSessionToken}>
      <FaceTecSessionTokenGuard
        uninit={FaceTecSessionLoadingPage}
        pending={FaceTecSessionLoadingPage}
        ready={ready}
        error={error}
      />
    </FaceTecSessionInit>
  );
};

type LegacyFaceTecCaptureProps = {
  sessionToken: string;
  onLivenessData: (livenessData: LivenessData) => Promise<LegacyEffect>;
  onDone: () => void;
  onError: (error: Error) => void;
};
const LegacyFaceTecCapture: React.FC<LegacyFaceTecCaptureProps> = (props) => {
  const { onDone, onError, onLivenessData, sessionToken } = props;

  const processor = useMemo(() => {
    return makeLegacyProcessor({
      onDone,
      handleLivenessData: onLivenessData,
      onError,
    });
  }, [onDone, onError, onLivenessData]);

  const [startCapture, facetecCaptureInProgress] = useStartCapture(
    processor,
    sessionToken,
  );

  const autostartTriggeredRef = useRef(false);
  useEffect(() => {
    if (!autostartTriggeredRef.current && !facetecCaptureInProgress) {
      autostartTriggeredRef.current = true;
      startCapture();
    }
  }, [facetecCaptureInProgress, startCapture]);

  return null;
};

type FaceTecCaptureProps = {
  sessionToken: string;
  onLivenessData: LivenessDataHandler;
  updateCaptureState: (state: React.SetStateAction<CaptureState>) => void;
};
const FaceTecCapture: React.FC<FaceTecCaptureProps> = (props) => {
  const { updateCaptureState, onLivenessData, sessionToken } = props;

  const processor = useMemo(() => {
    const onDone = () => {
      updateCaptureState((prevState) => {
        if (prevState.tag === "error") return prevState;

        return {
          tag: "done",
        };
      });
    };
    const onError = (error: Error) => {
      updateCaptureState({
        tag: "error",
        data: { error },
      });
    };
    return makeProcessor({
      onDone,
      handleLivenessData: onLivenessData,
      onError,
    });
  }, [updateCaptureState, onLivenessData]);

  const [startCapture, facetecCaptureInProgress] = useStartCapture(
    processor,
    sessionToken,
  );

  const autostartTriggeredRef = useRef(false);
  useEffect(() => {
    if (!autostartTriggeredRef.current && !facetecCaptureInProgress) {
      autostartTriggeredRef.current = true;
      startCapture();
    }
  }, [facetecCaptureInProgress, startCapture]);

  return null;
};

const useLegacyCaptureFlow = (
  onLivenessData: (
    api: ApiRx,
    livenessData: LivenessData,
  ) => Promise<LegacyEffect>,
) => {
  const [captureDone, setCaptureDone] = useState(false);
  const [captureError, setCaptureError] = useState<Error | null>(null);

  const { api } = useApi();
  const { sessionToken } = useFaceTecSessionToken();

  const handleLivenessData = useCallback(
    (livenessData: LivenessData) => onLivenessData(api, livenessData),
    [api, onLivenessData],
  );

  const handleDone = useCallback(() => {
    setCaptureDone(true);
  }, []);
  const handleError = useCallback((error: Error) => {
    setCaptureError(error);
  }, []);
  const handleCaptureAgain = useCallback(() => {
    setCaptureDone(false);
    setCaptureError(null);
  }, []);

  return {
    handleDone,
    handleCaptureAgain,
    handleError,
    handleLivenessData,
    sessionToken,
    captureDone,
    captureError,
  };
};

type CaptureState =
  | { tag: "capturing" }
  | { tag: "done" }
  | { tag: "error"; data: { error: Error } };

type CaptureFlowProps = {
  header: string;
  onLivenessData: LivenessDataHandler;
  updateCaptureState: (state: React.SetStateAction<CaptureState>) => void;
};
const CaptureFlow: React.FC<CaptureFlowProps> = (props) => {
  const { sessionToken } = useFaceTecSessionToken();

  return (
    <Layout logo>
      <Container maxWidth="xs">
        <Stack spacing={2} textAlign="center" alignItems="center">
          <Typography variant="h4" align="center">
            {props.header}
          </Typography>
          <FaceTecCapture
            updateCaptureState={props.updateCaptureState}
            sessionToken={sessionToken}
            onLivenessData={props.onLivenessData}
          />
          <CircularProgress />
          <Typography align="center">Capturing biometric data...</Typography>
          <GoBackButton />
        </Stack>
      </Container>
    </Layout>
  );
};

type LegacyCaptureFlowProps = {
  onLivenessData: (
    api: ApiRx,
    livenessData: LivenessData,
  ) => Promise<LegacyEffect>;
  failureHeader: string;
  successHeader: string;
  captureHeader: string;
};

const LegacyCaptureFlow: React.FC<LegacyCaptureFlowProps> = (props) => {
  const { onLivenessData, captureHeader, failureHeader, successHeader } = props;
  const {
    handleDone,
    handleCaptureAgain,
    handleError,
    handleLivenessData,
    sessionToken,
    captureDone,
    captureError,
  } = useLegacyCaptureFlow(onLivenessData);

  return captureDone ? (
    captureError ? (
      <Layout logo>
        <Container maxWidth="xs">
          <Stack spacing={2} textAlign="center" alignItems="center">
            <Typography variant="h4" align="center">
              {failureHeader}
            </Typography>
            <Typography align="center">{captureError.message}</Typography>
            <CaptureAgainButton onClick={handleCaptureAgain} />
            <GoBackButton />
          </Stack>
        </Container>
      </Layout>
    ) : (
      <Layout logo>
        <Container maxWidth="xs">
          <Stack spacing={2} textAlign="center" alignItems="center">
            <Typography variant="h4" align="center">
              {successHeader}
            </Typography>
            <GoBackButton />
          </Stack>
        </Container>
      </Layout>
    )
  ) : (
    <Layout logo>
      <Container maxWidth="xs">
        <Stack spacing={2} textAlign="center" alignItems="center">
          <Typography variant="h4" align="center">
            {captureHeader}
          </Typography>
          <LegacyFaceTecCapture
            onDone={handleDone}
            onError={handleError}
            sessionToken={sessionToken}
            onLivenessData={handleLivenessData}
          />
          <CircularProgress />
          <Typography align="center">Capturing biometric data...</Typography>
          <GoBackButton />
        </Stack>
      </Container>
    </Layout>
  );
};

const tryBioauthCall = async (
  call: () => Promise<void>,
): Promise<LegacyEffect> => {
  try {
    await call();
    return "success";
  } catch (error: unknown) {
    if (error instanceof RpcError) {
      const { data } = error;
      if (isShouldRetry(data)) {
        console.log("Retrying face scan for caught error: ", error);
        return "retry";
      }
    }

    throw error;
  }
};

const legacyEnrollEffect = async (
  api: ApiRx,
  livenessData: LivenessData,
): Promise<LegacyEffect> => tryBioauthCall(() => enroll(api, livenessData));

const legacyAuthenticateEffect = async (
  api: ApiRx,
  livenessData: LivenessData,
): Promise<LegacyEffect> =>
  tryBioauthCall(() => authenticate(api, livenessData));

const RpcControllers: React.FC<ChildrenProps> = (props) => {
  const { children } = props;
  const { api } = useApi();

  const observable$ = useMemo(
    () => api.rpc.chain.subscribeAllHeads().pipe(skip(1)),
    [api.rpc.chain],
  );

  return (
    <DashboardInit api={api} observable$={observable$}>
      {children}
    </DashboardInit>
  );
};

const CommonBoundary: React.FC<ChildrenProps> = (props) => {
  const { children } = props;
  const configLoaderState = useConfigLoader();

  const ConfigLoaderErrorPage = useExtendedError(
    <>Unable to Load Сonfiguration</>,
  );

  if (configLoaderState.tag === "pending") {
    return <ConfigLoaderPending />;
  }

  if (configLoaderState.tag === "error") {
    return <ConfigLoaderErrorPage error={configLoaderState.error} />;
  }

  return (
    <ServiceWorkerGuard notReady={<ServiceWorkerLoadingPage />}>
      <ApiConnector>
        <RpcControllers>{children}</RpcControllers>
      </ApiConnector>
    </ServiceWorkerGuard>
  );
};

const UnsupportedFlowPage: React.FC = () => (
  <Layout logo>
    <Typography variant="h5" align="center">
      Your Peer Is Incompatible
    </Typography>
    <Typography variant="body2">
      Your peer doesn't support Humanode API.
      <ul>
        <li>Please check the RPC URL you have specified</li>
        <li>
          or make sure you've deployed the correct peer version to the specified
          address.
        </li>
      </ul>
    </Typography>
    <Button fullWidth variant="text" component={Link} to="/">
      Change RPC URL
    </Button>
  </Layout>
);

const CaptureFlowBase: React.FC<ChildrenProps> = (props) => {
  const { children } = props;
  const ready = useCallback(
    () => <FaceTecSessionInitializer>{children}</FaceTecSessionInitializer>,
    [children],
  );
  const error = useExtendedError(
    <>Error While Initializing the Biometric Engine</>,
    undefined,
    true,
  );

  return (
    <FaceTecSDKInitializer>
      <FaceTecSDKGuard
        uninit={FaceTecSDKLoadingPage}
        pending={FaceTecSDKLoadingPage}
        ready={ready}
        failed={FaceTecSDKFailedPage}
        error={error}
      />
    </FaceTecSDKInitializer>
  );
};

const LegacyAuthenticatePage: React.FC = () => (
  <CaptureFlowBase>
    <LegacyCaptureFlow
      onLivenessData={legacyAuthenticateEffect}
      captureHeader="Authenticating..."
      successHeader="Authentication Complete"
      failureHeader="Authentication Failed"
    />
  </CaptureFlowBase>
);

const AuthTxSending = () => (
  <Layout logo>
    <Container maxWidth="xs">
      <Stack spacing={2} textAlign="center" alignItems="center">
        <Typography variant="h4" align="center">
          Authenticating...
        </Typography>
        <CircularProgress />
        <Typography align="center">Sending transaction...</Typography>
      </Stack>
    </Container>
  </Layout>
);

const authTxStatusMap: Record<
  ExtrinsicStatus["type"],
  (status: ExtrinsicStatus) => React.ReactNode
> = {
  Future: () => "Transaction in the future queue",
  Ready: () => "Transaction in the ready queue",
  Broadcast: () => "Transaction broadcasted into the gossip network",
  InBlock: (status: ExtrinsicStatus) =>
    `Transaction included in block ${status.asInBlock}`,
  Retracted: () =>
    `The block that included the transcation was removed from canonical chain`,
  FinalityTimeout: () => `Status unavailable: too many watchers on the node`,
  Finalized: (status: ExtrinsicStatus) =>
    `Transaction finalized in block ${status.asFinalized}`,
  Usurped: (status: ExtrinsicStatus) =>
    `Transaction was replaced in by another transaction ${status.asUsurped}`,
  Dropped: () => `Transaction was dropped from the pool due to pool pressure`,
  Invalid: () => `Transaction has become invalid`,
};

const AuthTxStatus: React.FC<{ status: ExtrinsicStatus }> = (props) => {
  const isFinalized = useMemo(
    () => props.status.type === "Finalized",
    [props.status],
  );

  const statusMessage = useMemo(
    () => authTxStatusMap[props.status.type](props.status),
    [props.status],
  );

  return (
    <Layout logo>
      <Container maxWidth="xs">
        <Stack spacing={2} textAlign="center" alignItems="center">
          <Typography variant="h4" align="center">
            {isFinalized ? "Authentication Complete" : "Authenticating..."}
          </Typography>
          {!isFinalized ? <CircularProgress /> : <CompleteIcon />}
          <Typography align="center">{statusMessage}</Typography>
          {isFinalized ? <GoBackButton /> : null}
        </Stack>
      </Container>
    </Layout>
  );
};

type SignAndSendAuthenticateTxPageProps = {
  authTicket: Uint8Array;
  authTicketSignature: Uint8Array;
};
const SignAndSendAuthenticateTxPage: React.FC<
  SignAndSendAuthenticateTxPageProps
> = (props) => {
  const { api } = useApi();
  const [sendAuthenticateTxState, setSendAuthenticateTxState] =
    useState<SendAuthenticateTxState>();

  useEffect(() => {
    let sub = sendAuthenticateTx(
      api,
      props.authTicket,
      props.authTicketSignature,
    ).subscribe({
      next: setSendAuthenticateTxState,
    });

    return () => sub.unsubscribe();
  }, [api, props]);

  if (sendAuthenticateTxState === undefined) {
    return <AuthTxSending />;
  }

  if (sendAuthenticateTxState.tag === "status") {
    return <AuthTxStatus status={sendAuthenticateTxState.status} />;
  }

  return (
    <AuthTxErrorPage
      kind={sendAuthenticateTxState.specialErrorPage}
      error={sendAuthenticateTxState.error}
    />
  );
};

const AuthenticatePage: React.FC = () => {
  const { api } = useApi();
  const [captureState, setCaptureState] = useState<CaptureState>({
    tag: "capturing",
  });

  const [authenticateV2Result, setAuthenticateV2Result] =
    useState<AuthenticateV2Response>();

  const handleCaptureAgain = useCallback(() => {
    setCaptureState({ tag: "capturing" });
  }, []);

  const onLivenessData = useCallback<LivenessDataHandler>(
    async (livenessData) => {
      const res = await authenticateV2(api, livenessData);
      setAuthenticateV2Result(res);

      if (
        res.data &&
        "scanResultBlob" in res.data &&
        res.data.scanResultBlob !== undefined
      ) {
        return { blob: res.data.scanResultBlob };
      }

      if (res.tag === "success") {
        return { command: "success" };
      }

      if (res.tag === "error" && res.data && "shouldRetry" in res.data) {
        return { command: "retry" };
      }

      return undefined;
    },
    [api],
  );

  if (captureState.tag === "error") {
    return (
      <Layout logo>
        <Container maxWidth="xs">
          <Stack spacing={2} textAlign="center" alignItems="center">
            <Typography variant="h4" align="center">
              Authentication Failed
            </Typography>
            <Typography align="center">
              {captureState.data.error.message}
            </Typography>
            <CaptureAgainButton onClick={handleCaptureAgain} />
            <GoBackButton />
          </Stack>
        </Container>
      </Layout>
    );
  }

  if (captureState.tag === "capturing") {
    return (
      <CaptureFlowBase>
        <CaptureFlow
          header="Authenticating..."
          onLivenessData={onLivenessData}
          updateCaptureState={setCaptureState}
        />
      </CaptureFlowBase>
    );
  }

  if (authenticateV2Result) {
    if (authenticateV2Result.tag === "success") {
      return (
        <SignAndSendAuthenticateTxPage
          authTicket={authenticateV2Result.data.authTicket}
          authTicketSignature={authenticateV2Result.data.authTicketSignature}
        />
      );
    }

    if (authenticateV2Result.tag === "error") {
      return (
        <AuthenticateV2ErrorPage
          kind={authenticateV2Result.specialErrorPage}
          error={authenticateV2Result.error}
        />
      );
    }

    throw new Error("unreachable");
  }

  return <AuthTxSending />;
};

const LegacyEnrollPage: React.FC = () => (
  <CaptureFlowBase>
    <LegacyCaptureFlow
      onLivenessData={legacyEnrollEffect}
      captureHeader="Enrolling..."
      successHeader="Enrollment Complete"
      failureHeader="Enrollment Failed"
    />
  </CaptureFlowBase>
);

const EnrollPage: React.FC = () => {
  const { api } = useApi();
  const [captureState, setCaptureState] = useState<CaptureState>({
    tag: "capturing",
  });
  const [enrollResult, setEnrollResult] = useState<EnrollV2Response>();

  const handleCaptureAgain = useCallback(() => {
    setCaptureState({ tag: "capturing" });
  }, []);

  const onLivenessData = useCallback<LivenessDataHandler>(
    async (livenessData) => {
      const res = await enrollV2(api, livenessData);
      setEnrollResult(res);

      if (
        res.data &&
        "scanResultBlob" in res.data &&
        res.data.scanResultBlob !== undefined
      ) {
        return { blob: res.data.scanResultBlob };
      }

      if (res.tag === "success") {
        return { command: "success" };
      }

      if (res.tag === "error" && res.data && "shouldRetry" in res.data) {
        return { command: "retry" };
      }

      return undefined;
    },
    [api],
  );

  if (captureState.tag === "error") {
    return (
      <Layout logo>
        <Container maxWidth="xs">
          <Stack spacing={2} textAlign="center" alignItems="center">
            <Typography variant="h4" align="center">
              Enrollment Failed
            </Typography>
            <Typography align="center">
              {captureState.data.error.message}
            </Typography>
            <CaptureAgainButton onClick={handleCaptureAgain} />
            <GoBackButton />
          </Stack>
        </Container>
      </Layout>
    );
  }

  if (captureState.tag !== "done") {
    return (
      <CaptureFlowBase>
        <CaptureFlow
          header="Enrolling..."
          onLivenessData={onLivenessData}
          updateCaptureState={setCaptureState}
        />
      </CaptureFlowBase>
    );
  }

  if (enrollResult && enrollResult.tag === "error") {
    return (
      <EnrollV2ErrorPage
        error={enrollResult.error}
        kind={enrollResult.specialErrorPage}
      />
    );
  }

  return (
    <Layout logo>
      <Container maxWidth="xs">
        <Stack spacing={2} textAlign="center" alignItems="center">
          <Typography variant="h4" align="center">
            Enrollment Complete
          </Typography>
          <CompleteIcon />
          <GoBackButton />
        </Stack>
      </Container>
    </Layout>
  );
};

type SetKeysButtonState =
  | StateVariant<"uninit">
  | StateVariant<"pending">
  | StateVariant<"completed">
  | StateVariant<"error", { error: Error }>;

const RotateAndSetKeysPage: React.FC = () => {
  const { api } = useApi();
  const [state, setState] = useState<SetKeysButtonState>({
    tag: "uninit",
    data: {},
  });

  const setKeysHandler = useAsyncCallback(
    async () => {
      setState({ tag: "pending", data: {} });
      const keys = await rotateKeys(api);
      await setKeys(api, keys);
      setState({ tag: "completed", data: {} });
    },
    (error) => {
      setState({
        tag: "error",
        data: { error: mapSubstrateErrors(error) },
      });
    },
    [api],
  );

  const ButtonStatus = useMemo(() => {
    if (state.tag === "pending") {
      return <CircularProgress size={12} color="primary" />;
    }
    if (state.tag === "completed") {
      return <CheckIcon fontSize="inherit" />;
    }
  }, [state.tag]);

  const RotateAndSetKeysErrorPage = useExtendedError(
    <>Unable to Set Session Keys</>,
    <>
      This might be caused by:
      <ul>
        <li>account balance too low.</li>
      </ul>
    </>,
    true,
  );

  if (state.tag === "error") {
    return <RotateAndSetKeysErrorPage error={state.data.error} />;
  }

  return (
    <Layout logo>
      <Container maxWidth="xs">
        <Stack spacing={2} textAlign="center">
          <Button
            fullWidth
            color="primary"
            size="large"
            variant="contained"
            onClick={setKeysHandler}
            disabled={state.tag === "pending"}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: 12, display: "flex" }}>{ButtonStatus}</Box>
            <Typography marginX={2}>Rotate and set keys</Typography>
            <Box sx={{ width: 12 }} />
          </Button>
          <Typography variant="caption">
            By clicking the "rotate and set keys" button, you will generate new
            session keys and submit a transaction to link them with your
            validator key.
          </Typography>
          <Typography variant="caption">
            This action will override the session keys that were previously
            connected to your validator key (if they exist).
          </Typography>
          <GoBackButton />
        </Stack>
      </Container>
    </Layout>
  );
};

const RotateAndSetKeysWarningPage: React.FC = () => (
  <Layout logo>
    <Container maxWidth="sm">
      <Stack spacing={2}>
        <Alert severity="warning">Setting session keys is not free</Alert>
        <Stack spacing={1}>
          <Typography variant="overline">
            Session keys connected to your validator key are required to
            participate in the Humanode Network.
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <Typography variant="caption">
            If you have lost access to your node or decide to move your node to
            a different machine you will need to regenerate the keys and link
            them with your validator key.
          </Typography>
        </Stack>
        <Button
          size="large"
          variant="contained"
          component={Link}
          to="../rotate-and-set-keys"
        >
          Yes, I understand
        </Button>
        <Button size="large" component={Link} to="..">
          Cancel
        </Button>
      </Stack>
    </Container>
  </Layout>
);

const EnrollWarningPage: React.FC = () => (
  <Layout logo>
    <Container maxWidth="sm">
      <Stack spacing={2}>
        <Alert severity="warning">As a human, you can only enroll once!</Alert>
        <Stack spacing={1}>
          <Typography variant="overline">
            You naturally only have a single biometric identity.
          </Typography>
          <Typography variant="overline">
            Humanode Network is designed to ensure one human can't run more than
            one node.
          </Typography>
          <Typography variant="overline">
            You can only link one private key to your biometric identity.
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <Typography variant="caption">
            Make sure you can recover you private key in case you loose access
            to this instance of the node by saving the mnemonic phrase for your
            private key.
          </Typography>
          <Typography variant="caption">
            If you lose the private key after you link it to your biometric
            identity, you will loose access to the network and will be unable to
            participate in the Humanode Network.
          </Typography>
        </Stack>
        <Button
          size="large"
          variant="contained"
          component={Link}
          to="../enroll"
        >
          Yes, I understand
        </Button>
        <Button size="large" component={Link} to="..">
          Cancel
        </Button>
      </Stack>
    </Container>
  </Layout>
);

const SetupNodePage: React.FC = () => (
  <Layout logo>
    <Container maxWidth="xs">
      <Stack spacing={4} textAlign="center" alignItems="center">
        <Stack spacing={1} width="100%">
          <Button
            size="large"
            variant="outlined"
            component={Link}
            to="rotate-and-set-keys-warning"
          >
            Rotate and set session keys
          </Button>
          <Typography variant="caption">
            Generate new node session keys and link them to your node's private
            key. This operation submits a transaction, so you need to have
            tokens to do this.
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <Button
            size="large"
            variant="outlined"
            component={Link}
            to="enroll-warning"
          >
            Enroll
          </Button>
          <Typography variant="caption">
            Link your biometric information to the node, permanently connecting
            the node's private key to your biometric. Enrollment is done only
            once when you launch the node for the first time.
          </Typography>
        </Stack>
        <GoBackButton />
      </Stack>
    </Container>
  </Layout>
);

type DashboardPageProps = {
  showOutdatedNodeWarning: boolean;
};
const DashboardPage: React.FC<DashboardPageProps> = (props) => (
  <Layout logo>
    <Container maxWidth="xs">
      <Accordion
        sx={{
          width: "100%",
          boxShadow: "none",
          marginBottom: 4,
          borderRadius: "4px",
        }}
      >
        {props.showOutdatedNodeWarning && (
          <Alert severity="warning">
            You are using an outdated node's version, please visit to your node
            to update, follow node's settings and scroll down to section Updates
            and check for peer updates. This version includes deprecated APIs
            and could work unstable.
          </Alert>
        )}
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="button">Dashboard</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{ display: "block", bgcolor: "#121212", paddingBottom: 0 }}
        >
          <DashboardView />
        </AccordionDetails>
      </Accordion>
      <Stack spacing={3} textAlign="center" alignItems="center">
        <Stack spacing={1}>
          <Button
            size="large"
            variant="contained"
            color="success"
            component={Link}
            to="authenticate"
          >
            Authenticate
          </Button>
          <Typography variant="caption">
            Authenticate the node to the Humanode Network using your biometric
            information to start participating in consensus.
          </Typography>
          <Typography variant="caption">
            You must be enrolled and have your node's keys linked to your
            account before you can authenticate.
          </Typography>
        </Stack>
        <Stack spacing={1} width="100%">
          <Button
            size="large"
            variant="outlined"
            color="primary"
            component={Link}
            to="setup-node"
          >
            Setup the node
          </Button>
          <Typography variant="caption">
            Perform the initial setup of the node by configuring its session
            keys and linking it with your biometric information.
          </Typography>
        </Stack>
      </Stack>
    </Container>
  </Layout>
);

const hasNewFlowRpcs = (api: ApiRx): boolean =>
  api.rpc.bioauth.enrollV2 !== undefined &&
  api.rpc.bioauth.authenticateV2 !== undefined;

const hasLegacyFlowRpcs = (api: ApiRx): boolean =>
  api.rpc.bioauth.authenticate !== undefined &&
  api.rpc.bioauth.enroll !== undefined;

const FlowPage: React.FC = () => {
  const { api } = useApi();
  const config = useConfig();
  const useNewFlow = useMemo(
    () => (config.forceLegacyFlow === true ? false : hasNewFlowRpcs(api)),
    [api, config],
  );
  const isSupported = useMemo(
    () =>
      hasLegacyFlowRpcs(api) ||
      (!config.forceLegacyFlow && hasNewFlowRpcs(api)),
    [api, config],
  );

  if (!isSupported) return <UnsupportedFlowPage />;

  return (
    <Routes>
      <Route
        index
        element={
          <DashboardPage
            showOutdatedNodeWarning={
              !useNewFlow && config.showOutdatedNodeWarning
            }
          />
        }
      />
      <Route
        path="authenticate"
        element={useNewFlow ? <AuthenticatePage /> : <LegacyAuthenticatePage />}
      />
      <Route path="setup-node">
        <Route index element={<SetupNodePage />} />
        <Route path="enroll-warning" element={<EnrollWarningPage />} />
        <Route
          path="rotate-and-set-keys-warning"
          element={<RotateAndSetKeysWarningPage />}
        />
        <Route
          path="enroll"
          element={useNewFlow ? <EnrollPage /> : <LegacyEnrollPage />}
        />
        <Route path="rotate-and-set-keys" element={<RotateAndSetKeysPage />} />
      </Route>
    </Routes>
  );
};

const MainPage: React.FC = () => (
  <CommonBoundary>
    <FlowPage />
  </CommonBoundary>
);

export default MainPage;
